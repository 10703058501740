<template>
  <ciam-page :title-suffix="$t('pageTitles.organization')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>
          <ciam-text>{{ $t('organization.serviceAccounts.create.header', { name: organizationName }) }}</ciam-text>
        </template>
        <template v-slot:subtitle>{{ $t('organization.serviceAccounts.create.subtitle') }}</template>
      </ciam-card-header>

      <ciam-roles v-model="form.role" />

      <ciam-card-footer>

        <ciam-alert v-if="problem" :title="problem.title" :description="problem.detail"
                    :type="AlertStatus.ALERT"></ciam-alert>

        <ciam-button class="outlined" @click="cancel()">{{ $t('actions.cancel') }}</ciam-button>
        <ciam-button class="primary" :loading="isLoading" :disabled="!isValid" @click="applyCreateServiceAccount()">
          {{ $t('organization.serviceAccounts.create.action') }}
        </ciam-button>
      </ciam-card-footer>
    </ciam-card>
    <b-modal
      :active.sync="parametersModal.isOpened"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <standard-modal
        :buttons="parametersModal.buttons"
      >
        <template v-slot:header>{{ $t('organization.serviceAccounts.create.header', { name: organizationName }) }}
        </template>
        <template v-slot:default>
          <ciam-card-content-line type="split-content-component">
            <template v-slot:label>{{ $t('organization.serviceAccounts.create.modale.clientId') }}</template>
            <template v-slot:content>
              <ciam-input
                type="text"
                v-model="parametersModal.parameters.clientId"
                :placeholder="$t('organization.serviceAccounts.create.modale.clientId')"
              />
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line type="split-content-component">
            <template v-slot:label>{{ $t('organization.serviceAccounts.create.modale.clientSecret') }}</template>
            <template v-slot:content>
              <ciam-input
                type="text"
                v-model="parametersModal.parameters.clientSecret"
                :placeholder="$t('organization.serviceAccounts.create.modale.clientSecret')"
              />
            </template>
          </ciam-card-content-line>

          <div class="w-auto px-5">
            <ciam-alert
              :title="$t('organization.serviceAccounts.create.modale.copyClientSecretTitle')"
              :description="$t('organization.serviceAccounts.create.modale.copyClientSecretDescription')"
              :type="AlertStatus.WARNING"
            />
          </div>

          <div class="curl-cmd">
            <ciam-text>
              {{ curlCmdPayload }}
            </ciam-text>
          </div>

          <ciam-card-footer class="w-auto">
            <ciam-button class="primary" @click="redirectToOrganization()">
              {{ $t('organization.serviceAccounts.create.modale.redirect') }}
            </ciam-button>
          </ciam-card-footer>

        </template>
      </standard-modal>
    </b-modal>

  </ciam-page>
</template>

<script>
import CiamAlert from '@/components/CiamAlert';
import { AlertStatus } from '@/components/CiamAlert';
import OrganizationService from '@/pages/organizations/OrganizationService';
import { routeNames } from '@/router';
import CiamInput from '@/components/CiamInput.vue';
import StandardModal from '@/components/StandardModal.vue';
import CiamRoles from '@/components/CiamRoles.vue';

export default {
  name: 'CreateServiceAccount',
  components: {
    StandardModal, CiamInput,
    CiamAlert,
    CiamRoles,
  },
  data() {
    return {
      curlCmdPayload: '',
      isLoading: false,
      parametersModal: {
        isOpened: false,
        parameters: {
          clientId: null,
          clientSecret: null,
          tokenUrl: null,
        },
      },
      AlertStatus: AlertStatus,
      problem: null,
      organizationName: '...',
      form: {
        role: null,
      },
    };
  },

  computed: {
    isValid() {
      return this.form.role !== null && this.form.role.length > 0;
    },
    organizationId() {
      return this.$route.params.id;
    },
  },
  methods: {
    redirectToOrganization() {
      this.$router
        .push({
          name: routeNames.OrganizationDetails,
          query: {
            organization_id: this.organizationId,
          },
          params: { id: this.organizationId },
        })
        .catch(() => {
        });
    },
    showModal(clientId, clientSecret, tokenUrl) {
      this.parametersModal.parameters.clientId = clientId;
      this.parametersModal.parameters.clientSecret = clientSecret;
      this.parametersModal.parameters.tokenUrl = tokenUrl;
      this.parametersModal.isOpened = true;
    },
    cancel() {
      this.$router
        .push({
          name: routeNames.OrganizationDetails,
          query: {
            organization_id: this.organizationId,
          },
          params: { id: this.organizationId },
        })
        .catch(() => {
        });
    },
    applyCreateServiceAccount() {
      this.isLoading = true;
      OrganizationService.createServiceAccount(this.organizationId, this.form.role)
        .then((response) => {
          this.curlCmdPayload = `curl ${response.data.tokenUrl} \
  -H "Content-Type: application/x-www-form-urlencoded" \
  -d "grant_type=client_credentials" \
  -d "client_id=${response.data.clientId}" \
  -d "client_secret=${response.data.clientSecret}"`;
          this.showModal(response.data.clientId, response.data.clientSecret, response.data.tokenUrl);
          this.isLoading = false;
        })
        .catch((problem) => {
          this.problem = problem;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.curl-cmd {
  @apply w-auto bg-gray-100 border rounded-md p-2 m-5 font-mono
}
</style>
